var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}]},[_c('can-i',{attrs:{"permissions":[_vm.$options.ROLE_UNIT_ADMINISTRATION]}},[_c('div',{staticClass:"unit-actions"},[(_vm.unitType !== _vm.$options.UNIT_TYPE_EMERGENCY)?_c('v-button',{attrs:{"icon":"edit"},on:{"click":_vm.goToAdoptionEdit}},[_vm._v(" "+_vm._s(_vm.$t(("page_unit." + _vm.unitType + ".btn_bricks_edit")))+" ")]):_vm._e(),_c('v-button',{attrs:{"icon":"edit"},on:{"click":_vm.goToPurposeEdit}},[_vm._v(" Edytuj cel ")])],1)]),(_vm.unitType === _vm.$options.UNIT_TYPE_INSTITUTION)?_c('UnitPurposeItem',{attrs:{"total":_vm.unitPurposes.heartAdoption.children,"progress":_vm.unitPurposes.heartAdoption.supportedChildren,"label-current-progress":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_INSTITUTION) + ".currentProgress")),"label-difference":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_INSTITUTION) + ".difference")),"description":_vm.unitPurposes.heartAdoption.adoptionContent,"title":_vm.$t(("page_unit." + (_vm.$options.UNIT_TYPE_INSTITUTION) + ".purpose_subscription_title"))}}):_vm._e(),(_vm.unitType === _vm.$options.UNIT_TYPE_FAMILY)?_c('UnitPurposeItem',{attrs:{"total":_vm.totalFamilySupport,"progress":_vm.progressFamilySupport,"label-current-progress":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_FAMILY) + ".currentProgress")),"label-difference":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_FAMILY) + ".difference")),"description":_vm.unitPurposes.heartAdoption.adoptionContent,"title":_vm.$t(("page_unit." + (_vm.$options.UNIT_TYPE_FAMILY) + ".purpose_subscription_title"))},scopedSlots:_vm._u([{key:"current-progress",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.familySupportMoneyFormat(value))+" ")]}},{key:"progress-difference",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.familySupportMoneyFormat(value))+" ")]}}],null,false,1934382327)}):_vm._e(),_c('UnitPurposeItem',{attrs:{"total":_vm.unitPurposes.purpose.moneyPurpose,"progress":_vm.unitPurposes.purpose.collectedMoney,"label-current-progress":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_EMERGENCY) + ".currentProgress")),"label-difference":_vm.$t(("unitProgressLabels." + (_vm.$options.UNIT_TYPE_EMERGENCY) + ".difference")),"description":_vm.unitPurposes.purpose.purposeContent,"title":_vm.unitPurposes.purpose.purposeTitle},scopedSlots:_vm._u([{key:"current-progress",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(value))+" ")]}},{key:"progress-difference",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(value))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }