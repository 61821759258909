<template>
  <div v-loading="isLoading">
    <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
      <div class="unit-actions">
        <v-button
            v-if="unitType !== $options.UNIT_TYPE_EMERGENCY"
            icon="edit"
            @click="goToAdoptionEdit"
        >
          {{ $t(`page_unit.${unitType}.btn_bricks_edit`) }}
        </v-button>
        <v-button
            icon="edit"
            @click="goToPurposeEdit"
        >
          Edytuj cel
        </v-button>
      </div>
    </can-i>
    <UnitPurposeItem
        v-if="unitType === $options.UNIT_TYPE_INSTITUTION"
        :total="unitPurposes.heartAdoption.children"
        :progress="unitPurposes.heartAdoption.supportedChildren"
        :label-current-progress="$t(`unitProgressLabels.${$options.UNIT_TYPE_INSTITUTION}.currentProgress`)"
        :label-difference="$t(`unitProgressLabels.${$options.UNIT_TYPE_INSTITUTION}.difference`)"
        :description="unitPurposes.heartAdoption.adoptionContent"
        :title="$t(`page_unit.${$options.UNIT_TYPE_INSTITUTION}.purpose_subscription_title`)"
    />
    <UnitPurposeItem
        v-if="unitType === $options.UNIT_TYPE_FAMILY"
        :total="totalFamilySupport"
        :progress="progressFamilySupport"
        :label-current-progress="$t(`unitProgressLabels.${$options.UNIT_TYPE_FAMILY}.currentProgress`)"
        :label-difference="$t(`unitProgressLabels.${$options.UNIT_TYPE_FAMILY}.difference`)"
        :description="unitPurposes.heartAdoption.adoptionContent"
        :title="$t(`page_unit.${$options.UNIT_TYPE_FAMILY}.purpose_subscription_title`)"
    >
      <template #current-progress="{value}">
        {{ familySupportMoneyFormat(value) }}
      </template>
      <template #progress-difference="{value}">
        {{ familySupportMoneyFormat(value) }}
      </template>
    </UnitPurposeItem>
    <UnitPurposeItem
        :total="unitPurposes.purpose.moneyPurpose"
        :progress="unitPurposes.purpose.collectedMoney"
        :label-current-progress="$t(`unitProgressLabels.${$options.UNIT_TYPE_EMERGENCY}.currentProgress`)"
        :label-difference="$t(`unitProgressLabels.${$options.UNIT_TYPE_EMERGENCY}.difference`)"
        :description="unitPurposes.purpose.purposeContent"
        :title="unitPurposes.purpose.purposeTitle"
    >
      <template #current-progress="{value}">
        {{ moneyFormat(value) }}
      </template>
      <template #progress-difference="{value}">
        {{ moneyFormat(value) }}
      </template>
    </UnitPurposeItem>
  </div>
</template>

<script>
import UnitPurposeItem from '../../unit-page/unit-content/unit-tab-purposes/unit-purpose-item/UnitPurposeItem'
import VButton from '../../../components/ui/v-button/VButton'

import {
  UNIT_TYPE_INSTITUTION,
  UNIT_TYPE_EMERGENCY,
  UNIT_TYPE_FAMILY
} from '../../../admin/units/shared/constants/units-constants'

import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {fetchUnitPurposeList} from '../../unit-page/services/unit-sevice'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'

export default {
  name: 'UnitPurposes',
  ROLE_UNIT_ADMINISTRATION,
  components: {
    CanI,
    UnitPurposeItem,
    VButton
  },
  UNIT_TYPE_INSTITUTION,
  UNIT_TYPE_EMERGENCY,
  UNIT_TYPE_FAMILY,
  inject: ['unitID', 'accessUnitType'],
  data() {
    return {
      isLoading: false,
      unitType: '',
      unitPurposes: {
        heartAdoption: {
          children: 0,
          supportedChildren: 0,
          adoptionContent: '',
          monthlyCost: 0,
        },
        purpose: {
          collectedMoney: 0,
          moneyPurpose: 0,
          purposeContent: '',
          purposeTitle: ''
        }
      }
    }
  },
  created() {
    this.unitType = this.accessUnitType()
    this.fetchPurposes()
  },
  computed: {
    totalFamilySupport() {
      const { children, monthlyCost } = this.unitPurposes.heartAdoption

      return children * monthlyCost
    },
    progressFamilySupport() {
      const { supportedChildren, monthlyCost } = this.unitPurposes.heartAdoption

      return supportedChildren * monthlyCost
    }
  },
  methods: {
    moneyFormat,
    familySupportMoneyFormat(val) {
      const basicMoneyFormat = this.moneyFormat(val)
      const suffix = this.$t('months_short')
      return `${basicMoneyFormat}/${suffix}`
    },
    fetchPurposes() {
      const { unitID } = this
      this.isLoading = true
      fetchUnitPurposeList(unitID)
          .then((res) => {
            this.unitPurposes = res
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    goToAdoptionEdit() {
      const {unitID} = this
      const adoptionID = this.unitPurposes.heartAdoption.hearthAdoptionId

      this.$router.push({
        name: 'edit_adoption',
        params: {
          unitID,
          adoptionID
        }
      })
    },
    goToPurposeEdit() {
      const {unitID} = this
      const purposeID = this.unitPurposes.purpose.purposeId

      this.$router.push({
        name: 'edit_purpose',
        params: {
          unitID,
          purposeID
        }
      })
    }
  }
}
</script>

<style scoped>
.purpose-actions {
  margin-bottom: 1.5em;
}
</style>
