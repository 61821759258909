<template>
  <UnitProgressRenderless
      :total="total"
      :progress="progress"
  >
    <template #default="{currentProgress, progressPercentage, progressDifference}">
      <div class="purpose-item">
        <h2 class="heading">
          {{ title }}
        </h2>
        <div class="purpose-progress">
          <div class="purpose-funding">
            <div class="purpose-funding__item">
              <div class="heading heading--progress">
                <slot name="current-progress" :value="currentProgress">
                  {{ currentProgress }}
                </slot>
              </div>
              <div>
                {{ labelCurrentProgress }}
              </div>
            </div>
            <div class="purpose-funding__item">
              <div class="heading heading--progress">
                <slot name="progress-difference" :value="progressDifference">
                  {{ progressDifference }}
                </slot>
              </div>
              <div>{{ labelDifference }}</div>
            </div>
          </div>
          <el-progress color="#38D6C1" :percentage="progressPercentage" />
        </div>
        <div class="purpose-description editor-artifact-content ql-editor" v-html="description">
        </div>
      </div>
    </template>
  </UnitProgressRenderless>
</template>

<script>
import UnitProgressRenderless from '../../../../components/units/unit-progress/UnitProgressRenderless'
import 'quill/dist/quill.snow.css'

export default {
  name: 'UnitPurposeItem',
  components: {
    UnitProgressRenderless
  },
  props: {
    title: {
      type: String,
      required: true
    },
    labelCurrentProgress: {
      type: String,
      required: true
    },
    labelDifference: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    progress: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    unitType: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>

.purpose-item {
  &:not(&:last-child) {
    padding-bottom: 2em;
    border-bottom: 1px solid #E4E7ED;
  }
  margin-bottom: 1.7em;
}
.purpose-progress {
  margin-top: 1.5em;
}
.purpose-funding {
  display: flex;
  align-items: center;
  margin-bottom: 0.7em;
  &__item {
    margin-right: 2em;
  }
}
.purpose-description {
  margin-top: 1.2em;
}
.heading--progress {
  font-size: 1.3em;
}
</style>
